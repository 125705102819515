<template>
    <div>
        <v-overlay :value="overlay" style="z-index: 99999999999;">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            :items-per-page=itemsPerPage
            :loading=loadingTable
            class="elevation-1"
            item-key="product_id"
            key="list-products"
            :disable-sort="$vuetify.breakpoint.name == 'xs'"
            :options.sync="options"
            hide-default-footer
            disable-pagination
            dense
        >
            <template v-slot:top>
                <ExpandableFilters 
                    :filters=filters
                    @datafilters=getProducts
                />
            </template>
            <template v-slot:item.actions="{item}">
                <v-btn 
                    icon 
                    @click="editProduct(item.product_id)"
                >
                    <v-icon color='warning' >mdi-pencil</v-icon>
                </v-btn>	
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on" 
                            icon 
                            @click="statusWebProduct(item.product_id)"
                        >
                            <v-icon :color="`${item.sales_disc_when_not_avail == 1 ? 'success' : 'error'}`" >
                                {{item.sales_disc_when_not_avail == 1  ? 'mdi-eye' : 'mdi-eye-off'}}
                            </v-icon>
                        </v-btn>
                    </template>	
                    <span>{{item.sales_disc_when_not_avail == 1  ? 'Publicado' : 'Despublicado'}}</span>
                </v-tooltip>  
            </template>              
            <template v-slot:footer>
                <v-pagination
                    class='mt-10'
                    v-model=currentPage
                    :length=totalItems
                    @input="handlePageChange"
                    total-visible="15"
                ></v-pagination>
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialog" 
            max-width="1000"
            persistent
        >
            <v-tabs v-model=tab grow>
                <v-tab>
                    DESCRIPCIONES
                </v-tab>
                <v-tab>
                    IMÁGENES
                </v-tab>
                <v-tab>
                    PROMOCIONES
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item class='mt-6'>
                    <v-card flat class='my-2 mx-2'>
                        <v-form ref='form_description_data'>
                            <div class="mt-5">
                                <v-icon>mdi-pencil</v-icon>
                                <b>TÍTULO WEB DEL PRODUCTO</b>
                            </div>
                            <v-text-field
                                outlined
                                v-model="product.product_name_web" 
                                dense
                                placeholder="ESCRIBE AQUÍ EL TÍTULO WEB DEL PRODUCTO"
                                :rules="required"
                                :value=product.product_name_web
                            />
                            <div class="mt-5">
                                <v-icon>mdi-pencil</v-icon>
                                <b>DESCRIPCIÓN CORTA DEL PRODUCTO</b>
                            </div>
                            <tiptap-vuetify 
                                v-model="product.short_description" 
                                :extensions="extensions"
                                placeholder="ESCRIBE LA DESCRIPCIÓN CORTA DEL PRODUCTO"
                                :rules="required"
                            />
                            <div class="mt-5">
                                <v-icon>mdi-pencil</v-icon>
                                <b>DESCRIPCIÓN LARGA DEL PRODUCTO</b>
                            </div>
                            <tiptap-vuetify 
                                v-model="product.long_description" 
                                :extensions="extensions"
                                placeholder="ESCRIBE LA DESCRIPCIÓN LARGA DEL PRODUCTO"
                                :rules="required"
                            />
                            <v-col class='text-center mt-5'>
                                <v-btn 
                                    class="text-center" 
                                    color='primary'
                                    :loading=loadingBtn
                                    @click="storeWebDescription"
                                >
                                    <v-icon>mdi-content-save</v-icon> Guardar
                                </v-btn>
                                <v-btn 
                                    class="text-center" 
                                    color='default' 
                                    @click="close"
                                >
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6'>
                    <v-card flat class='my-2 mx-2'>
                        <v-alert
                            dense
                            icon="mdi-format-list-bulleted"
                            type="info"
                            class="mx-3"
                        >
                            CARGUE LA IMAGEN WEB DEL PRODUCTO, <b>TAMAÑO RECOMENDADO 800x800</b>
                        </v-alert>
                        <v-form ref='form_image_product'>
                            <v-file-input
                                v-model=image
                                label="Imagen del producto"
                                outlined
                                dense
                                :rules=required
                                prepend-icon=""
                                accept="image/*"
                                class="mx-3"
                                @change=setFile($event)
                            ></v-file-input>
                        </v-form>
                        <v-col cols=12>
                            <v-row>
                                <v-col cols=12 md=6>
                                    <v-card class="mx-auto">
                                        <template slot="progress">
                                        <v-progress-linear
                                            color="deep-purple"
                                            height="10"
                                            indeterminate
                                        ></v-progress-linear>
                                        </template>
                                        <v-img :src="urlImgMedium"></v-img>                        
                                        <v-card-title>IMAGEN DEL LISTADO</v-card-title>
                                    </v-card>
                                </v-col>
                                <v-col cols=12 md=6>
                                    <v-card class="mx-auto">
                                        <template slot="progress">
                                            <v-progress-linear
                                                color="deep-purple"
                                                height="10"
                                                indeterminate
                                            ></v-progress-linear>
                                        </template>
                                        <v-img :src="urlImgLarge"></v-img>                        
                                        <v-card-title>IMAGEN INTERNA</v-card-title>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-col class='text-center mt-5'>
                                <v-btn 
                                    class="text-center" 
                                    color='primary'
                                    :loading=loadingBtn
                                    @click="storeImgProduct"
                                >
                                    <v-icon>mdi-content-save</v-icon> Guardar
                                </v-btn>
                                <v-btn 
                                    class="text-center" 
                                    color='default' 
                                    @click="close"
                                >
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                        </v-col>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6'>
                    <div class="text-center">
                        <v-row>
                            <v-col cols="12" md="6"> 
                                <v-card class="mx-auto text-left">
                                    <v-card-title>PROMOCIONES</v-card-title>
                                   
                                    <v-list-item 
                                        v-for="(promo, p) in product.promociones"
                                        :key="p.product_id"
                                    >
                                        <v-list-item-content>
                                            <b>{{ promo.nombre_promo }}</b>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6"> 
                                <v-form ref='form_sticker_promo' v-if="typeof product.promociones !='undefined' && product.promociones.length">
                                    <v-file-input
                                        v-model=stickerPromo
                                        label="Sticker de la promoción"
                                        outlined
                                        dense
                                        :rules=required
                                        prepend-icon=""
                                        accept="image/*"
                                        class="mx-3"
                                        @change=setFileStickerPromo($event)
                                    ></v-file-input>
                                </v-form>
                                <v-card class="mx-auto">
                                    <div ref="printMe">
                                        <v-img :src=imagePromotion > 
                                            <img id="drag_with_image" ref="drag_with_image" :src="urlStickerPromo" style="position:absolute;z-index: 9999;cursor: move;left:50px;top:50px;">
                                        </v-img>
                                    </div>
                                    <v-card-title>IMAGEN DE LA PROMOCIÓN</v-card-title>
                                </v-card>                                
                            </v-col>
                        </v-row>
                        <v-col class='text-center mt-5'>
                            <v-btn 
                                color='primary'
                                :loading=loadingBtn
                                @click="printShotPromo"
                            >
                                <v-icon>mdi-content-save</v-icon> Guardar
                            </v-btn>
                            <v-btn 
                                class="text-center" 
                                color='default' 
                                @click="close"
                            >
                                <v-icon>mdi-cancel</v-icon> Cerrar
                            </v-btn>
                        </v-col>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </v-dialog>
    </div>
</template>
<script>
    import {mapState, mapMutations, mapActions} from 'vuex'
    import ExpandableFilters from '../general/ExpandableFilters'
    import { 
        TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, 
        Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, 
        HorizontalRule, History 
    } from 'tiptap-vuetify'
    import 'tiptap-vuetify/dist/main.css'
    import Vue from 'vue'
    import axios from 'axios'

    let newPosX = 0, newPosY = 0, startPosX = 0, startPosY = 0

    var IdInterval = setInterval(() => {

        let el = document.querySelector('#drag_with_image')

        if(el != null){

            clearInterval(IdInterval)

            el.addEventListener('mousedown', function(e){

                e.preventDefault()
            
                startPosX = e.clientX
                startPosY = e.clientY
                
                document.addEventListener('mousemove', mouseMove)
                
                document.addEventListener('mouseup', function(i){
                    document.removeEventListener('mousemove', mouseMove)
                })
                
            })

            function mouseMove(e) {
              
                newPosX = startPosX - e.clientX
                newPosY = startPosY - e.clientY
            
                startPosX = e.clientX
                startPosY = e.clientY

                el.style.top = (el.offsetTop - newPosY) + "px"
                el.style.left = (el.offsetLeft - newPosX) + "px"
                
            }

        }

    }, 1000)

    export default{
        name:'ProductsWebCommerce',
        components:{
            ExpandableFilters,
            TiptapVuetify
        },
        data: () => ({
            totalItems:1,
            currentPage:1,
            itemsPerPage:20,
            dialog:false,
            overlay:false,
            tab: null,
            image:null,
            imagePromotion:null,
            urlImgMedium:null,
            urlImgLarge:null,
            stickerPromo:null,
            urlStickerPromo:null,
            promoSelected:null,
            options:{},
            product:{
                promociones:[]
            },
            dataTable:[],
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [
                    Heading, {
                        options: {
                            levels: [1, 2, 3,4, 5, 6]
                        }
                    }
                ],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            headers: [
                { text: 'Código', value: 'product_id' },
                { text: 'Nombre web del producto', value: 'product_name_web' },
                { text: 'Nombre de marca', value: 'brand_name' },
                { text: 'Nombre interno', value: 'internal_name' },
                { text: 'Acciones', value: 'actions', width:'120px' }
            ],
            filters:[
                {
                    cols:6,
                    class:'py-0 py-md-2 col-md-4 pl-0 pl-sm-2',
                    v_model:'',
                    label: 'Código',
                    type:'input_text'
                },
                {
                    cols:6,
                    class:'py-0 py-md-2 col-md-4 pl-0 pl-sm-2',
                    v_model:'',
                    label: 'Nombre',
                    type:'input_text'
                },
                {
                    cols:6,
                    class:'py-0 py-md-2 col-md-4 pl-0 pl-sm-2',
                    v_model:'',
                    label: 'Estado',
                    type:'input_select',
                    text:'name',
                    value:'state',
                    items:[
                        {'state': 'active','name':'Activos'},
                        {'state':'inactive','name': 'Inactivos'}
                    ],
                },
            ],
            product:{
                product_id:null,
                internal_name:null,
                brand_name:null,
                product_name:null,
                sales_discontinuation_date:'',
                short_description:null,
                long_description:null,
                product_name_web:null,
                imgProduct:null
            },
            defaultProduct:{
                product_id:null,
                internal_name:null,
                brand_name:null,
                product_name:null,
                sales_discontinuation_date:'',
                short_description:null,
                long_description:null,
                product_name_web:null,
                imgProduct:null
            },
            required:[v => !!v || 'El campo es requerido'],    
        }),
        computed: {
            ...mapState('master',[
                'loadingTable',
                'tenantId',
                'loadingBtn',
                'paramAlertQuestion',
                'user',
                'token',
                'url',
                'urlApi'
            ]),
        },
        watch:{
            options: {
                handler () {
                    this.getProducts()
                },
                deep: true
            }
        },
        methods:{

            ...mapMutations('master',['setLoadingTable','setUrl','setLoadingBtn']),

            ...mapActions('master',['requestApi','alertNotification']),

            getProducts(){
                console.log(this.options)
                const {sortDesc} = this.options
                
                this.setLoadingTable(true)
                this.setUrl('administracion')
                this.dataTable=[]
                this.requestApi({
                    method: 'GET',
                    data: {
                        typeList: 'productsEcommerce',
                        page: this.currentPage, 
                        itemsPerPage: this.itemsPerPage,
                        state : this.filters[2].v_model,
                        code: this.filters[0].v_model,
                        name: this.filters[1].v_model,
                        sortDesc: typeof sortDesc[0] !='undefined' ? sortDesc[0] : false,
                    }
                }).then(res =>{

                    this.totalItems = res.data.page_count
                    this.dataTable = res.data._embedded.administracion
                                    
                }).then(()=>{
                    this.setLoadingTable(false)
                })

            },

            editProduct(productId){
                this.dialog=true
                this.setLoadingTable(true)
                let noProductId = typeof productId =='object'
                this.newRegister = !noProductId
                this.setUrl('administracion')

                this.requestApi({
                    method: 'GET',
                    data: {
                        typeList: 'dataProductweb',
                        productId
                    }
                }).then((res) =>{

                    const { large_image_url, medium_image_url, imgPromo } = res.data._embedded.administracion[0]

                    Object.assign(this.product,res.data._embedded.administracion[0])
                    this.urlImgMedium = `${this.urlApi}img/products/${medium_image_url}` 
                    this.urlImgLarge = `${this.urlApi}img/products/${large_image_url}` 

                    fetch(imgPromo).then(res2=>res2.blob())
                    .then(res =>{
                        this.imagePromotion = URL.createObjectURL(res)
                        this.dialog=true
                    })
                    
                }).then(()=>{
                    this.setLoadingTable(false)
                })

            },

            handlePageChange(value) {
                this.currentPage = value
                this.getProducts()
            },

            storeWebDescription(){

                this.overlay=true
              
                this.setUrl('product')

                this.requestApi({
                    method: 'POST',
                    data: {
                        typeStore: 'webInformation',
                        ...this.product,
                        productId: this.product.product_id
                    }
                }).then(res =>{
                    
                    this.getProducts()

                    this.alertNotification({
                        param:{
                            html: res.data.res.msg,
                            timer: 130000
                        }
                    })

                    this.overlay=false
                    
                }).then(()=>{
                   
                })

            },

            close(){
                this.product.product_id=null
                Object.assign(this.product,this.defaultProduct)
                this.dialog=false
            },

            storeImgProduct(){

                if(!this.$refs.form_image_product.validate())
                    return false

                this.setLoadingBtn(true)
                this.setUrl('product')
            
                let formData = new FormData()
                
                formData.append('imgProduct',this.image)
                formData.append('product_id',this.product.product_id)
                formData.append('tenantId','FINNOFARM')
                formData.append('user',this.user.user_ligin_id)
                formData.append('pass',this.user.pass)
                formData.append('typeStore','imageWeb')

                axios.post(
                    this.url,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer '+ this.token
                        }
                    }
                ).then(res => {
                    console.log(res)
                    
                    this.setLoadingBtn(false)
                    this.alertNotification({
                        param:{
                            html: res.data.res.msg,
                            timer: 130000
                        }
                    })
                    this.image=null
                
                }).catch(err=>{
                
                // console.log(err)
                    this.getProducts()
                    this.setLoadingBtn(false)

                    params={
                        title :'Error!',
                        html: err.data.detail,
                        icon:'error',
                        confirmButtonColor:'#d60400',
                        toast:false,
                        grow:false,
                        position:'center',
                        confirmButtonText:'Cerrar'
                    }

                    Vue.swal({...params});

                })

            },

            statusWebProduct(productId){

                Vue.swal({
                    html: "Esta seguro cambiar el estado del producto en la web?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cerrar",
                    ...this.paramAlertQuestion,
                }).then((result) => {
                    
                    if (result.isConfirmed) {

                        this.overlay =true
                        this.setUrl('administracion')

                        this.requestApi({
                            method: 'POST',
                            data: {
                                typeAction: 'statusWebProduct',
                                productId
                            }
                        }).then(res =>{
                            
                            this.overlay =false
                            this. getProducts()
                        }).catch(()=>{

                            this.overlay =false
                            
                        })

                    }
                
                })
                
            },

            setFile(event){

                this.product.imgProduct = typeof event != 'undefined' ? event : null
                this.imagePromotion = typeof event != 'undefined' ? event : null

                if(this.image != null && this.image!=''){

                    this.urlImgMedium = URL.createObjectURL(this.image)
                    this.urlImgLarge = URL.createObjectURL(this.image)
                    this.imagePromotion = URL.createObjectURL(this.image)
                  
                }else{
                    this.urlImgMedium = null
                    this.urlImgLarge= null
                    this.imagePromotion = null
                }

            },

            setFileStickerPromo(event){

                if(this.stickerPromo != null && this.stickerPromo!=''){

                    this.stickerPromo = typeof event != 'undefined' ? event : null
                    this.urlStickerPromo = URL.createObjectURL(this.stickerPromo)

                }else{

                    this.urlStickerPromo = null

                }
            },

            async printShotPromo(){
                let el =this.$refs.printMe
                let option ={
                    type: 'dataURL'
                }
   
                this.imagePromotion = await this.$html2canvas(el, option)
                
                this.setUrl('product')

                this.requestApi({
                    method: 'POST',
                    data: {
                        typeStore: 'imageWebPromo',
                        product_id: this.product.product_id,
                        imagePromotion: this.imagePromotion
                    }
                }).then(res =>{
                        console.log(res)
                    this.alertNotification({
                    param:{
                        html: res.data.res.msg,
                        timer: 130000
                    }
                })
                    this.stickerPromo=null
                    this.overlay=false
                    
                }).then(()=>{
                   
                    this.overlay=false

                })

            },
            
        }
    }

</script>